import axios from 'axios';
import {androidFun} from '@/assets/js/androidFun';
axios.defaults.timeout = 15000

/**
 * request 拦截器
 * */
axios.interceptors.request.use(
    config => {
        config.headers = {
            'access-token': androidFun.getUserInfo().token,
        };

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

/**
 * 响应拦截器即异常处理
 * */
axios.interceptors.response.use(response => {
    return response
}, err => {
    if (err.message.includes('timeout')) {
        console.log('')
    }

    if (err && err.response) {
        console.log('')
    } else {
        console.log('')
    }

    return Promise.resolve(err.response)
})

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {params: params}).then(response => {
            resolve(response.data);
        }).catch(err => {
            reject(err)
        })
    })
}

export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, {}).then(response => {
            resolve(response.data);
        }, err => {
            reject(err)
        })
    })
}
export function postBody(url, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, qs.stringify(data), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(
          (response) => {
            resolve(response.data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }