<script>
export default {
  mounted() {

  },
  methods:{

  },
}
</script>

<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<style lang="scss">

</style>
