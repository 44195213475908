import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入ali-oss上传方法
import { alioss } from './aliOSS/ali-oss'

/** androidFun */
window.getAppUserInfo = function(data) {
  console.log(data)
}
import {androidFun} from '@/assets/js/androidFun';
androidFun.getUserInfo('window.getAppUserInfo')
Vue.prototype.$androidFun = androidFun;


/** Vconsole */
import Vconsole from 'vconsole'
if (process.env.VUE_APP_CURRENTMODE !== 'PROD') {
  Vue.use(new Vconsole());
}


/** 引入vant **/
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);


/** api */
import {api} from '@/api/api';
Vue.prototype.$api = api;


Vue.config.productionTip = false


//  // 配置alioss
//  alioss.accessKeyId = Vue.prototype.$appDatas.accessKeyId;
//  alioss.accessKeySecret = Vue.prototype.$appDatas.accessKeySecret;
//  Vue.prototype.$alioss = alioss;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
