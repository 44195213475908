import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/orderDetails",
    name: "orderDetails",
    component: () => import("../views/orderDetails/index.vue"),
  },
  {
    path: "/detailsRule",
    name: "detailsRule",
    component: () => import("../views/detailsRule/index.vue"),
  },
  {
    path: "/orderComplete",
    name: "orderComplete",
    component: () => import("../views/orderComplete/index.vue"),
  },
  {
    path: "/incomeDetails",
    name: "incomeDetails",
    component: () => import("../views/incomeDetails/index.vue"),
  },
  {
    path: "/withdrawalRecord",
    name: "withdrawalRecord",
    component: () => import("../views/withdrawalRecord/index.vue"),
  },
  {
    path: "/withdrawalDetails",
    name: "withdrawalDetails",
    component: () => import("../views/withdrawalDetails/index.vue"),
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: () => import("../views/withdrawal/index.vue"),
  },
  {
    path: "/withdrawal20241111",
    name: "withdrawal20241111",
    component: () => import("../views/withdrawal20241111/index.vue"),
  },
  {
    path: "/invite",
    name: "invite",
    component: () => import("../views/invite/index.vue"),
  },
  {
    path: "/orderFlowUnion",
    name: "orderFlowUnion",
    component: () => import("../views/orderFlowUnion/index.vue"),
  },
  {
    path: "/invite/incomeInvite",
    name: "invite/incomeInvite",
    component: () => import("../views/invite/incomeInvite/index.vue"),
  },
  {
    path: "/invite/rule",
    name: "invite/rule",
    component: () => import("../views/invite/rule/index.vue"),
  },
  {
    path: "/invite/myFriend",
    name: "invite/myFriend",
    component: () => import("../views/invite/myFriend/index.vue"),
  },
  {
    path: "/policy/privacy",
    name: "/policy/privacy",
    component: () => import("../views/policy/privacy.vue"),
  },
  {
    path: "/policy/privacyYmyx",
    name: "/policy/privacyYmyx",
    component: () => import("../views/policy/privacyYmyx.vue"),
  },
  {
    path: "/policy/user",
    name: "/policy/user",
    component: () => import("../views/policy/user.vue"),
  },
  {
    path: "/policy/userYmyx",
    name: "/policy/userYmyx",
    component: () => import("../views/policy/userYmyx.vue"),
  },
  {
    path: "/policy/logout",
    name: "/policy/logout",
    component: () => import("../views/policy/logout.vue"),
  },
  {
    path: "/policy/logoutYmyx",
    name: "/policy/logoutYmyx",
    component: () => import("../views/policy/logoutYmyx.vue"),
  },
  {
    path: "/invite/inviteFriend",
    name: "invite/inviteFriend",
    component: () => import("../views/invite/inviteFriend/index.vue"),
  },
  {
    path: "/orderFlowOfficial",
    name: "orderFlowOfficial",
    component: () => import("../views/orderFlowOfficial/index.vue"),
  },
  {
    path: "/challenge/home",
    name: "challenge/home",
    component: () => import("../views/challenge/home/index.vue"),
  },
  {
    path: "/challenge/recode",
    name: "challenge/recode",
    component: () => import("../views/challenge/recode/index.vue"),
  },
  {
    path: "/challenge/rule",
    name: "challenge/rule",
    component: () => import("../views/challenge/rule/index.vue"),
  },
  {
    path: "/newuser/myRedEnvelop",
    name: "newuser/myRedEnvelop",
    component: () => import("../views/newuser/myRedEnvelop/index.vue"),
  },
  {
    path: "/searchPoi",
    name: "searchPoi",
    component: () => import("../views/searchPoi/index.vue"),
  },
  {
    path: "/brand",
    name: "brand",
    component: () => import("../views/brand/index.vue"),
  },
  {
    path: "/policy/cloudAccount",
    name: "/policy/cloudAccount",
    component: () => import("../views/policy/cloudAccount.vue"),
  },
  {
    path: "/policy/cloudAccountMini",
    name: "/policy/cloudAccountMini",
    component: () => import("../views/policy/cloudAccountMini.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test/test.vue"),
  },
  {
    path: "/video",
    name: "video",
    component: () => import("../views/video/video.vue"),
  },
  {
    path: "/QuestionList",
    name: "/QuestionList",
    component: () => import("../views/question/QuestionList.vue"),
  },
  {
    path: "/QuestionListDetails",
    name: "/QuestionListDetails",
    component: () => import("../views/question/QuestionListDetails.vue"),
  },
  {
    path: "/feedback",
    name: "/feedback",
    component: () => import("../views/question/feedback.vue"),
  },
  {
    path: "/MyFeedBack",
    name: "/MyFeedBack",
    component: () => import("../views/question/MyFeedBack.vue"),
  },
  {
    path: "/MyFeedBackDetails",
    name: "/MyFeedBackDetails",
    component: () => import("../views/question/MyFeedBackDetails.vue"),
  },
  {
    path: "/cloudAccountMini",
    name: "/cloudAccountMini",
    component: () => import("../views/policy/cloudAccountMini.vue"),
  },
  {
    path: "/CashbackRecord",
    name: "/CashbackRecord",
    component: () => import("../views/CashbackRecord/index.vue"),
  },
  
];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
