export let appEvent = window.actionlistner

export let androidFun = {
    getUserInfo,
    finishPage,
    openWxMini,
    openDeepLink,
    getAppFixedParams,
    aliAuth,
    openNewPage,
    getLoactionCache,
    copyText,
    setStatusBarColor,
    upDateTaskPhoneNum,
    shareWxByUrl,
    shareQQByUrl,
    sharePyqByUrl,
    shareImageByBase64,
    shareWxMini,
    saveImageForBase64,
    noticeOrderChange,
    login,
    openOrderCheck,
    showLoading,
    hideLoading,
    getAppParams,
    wxAuth,
    showBindPhoneDialog,
    searchPoi,
    selectPoi,
    selectCurrentPoi,
    getStringCache,
    shareWxMiniByBitmap,
    showOpenPoiQrCode,
    setNativeCompress,
    saveImageForUrl,
    checkApkExist,
}

function getUserInfo() {
    if (appEvent) {
        let userInfo = appEvent.getUserInfo();
        if(userInfo){
            return JSON.parse(userInfo);
        }
        return {
            userId: '',
            nickName: '',
            token: '',
            invitationCode: '',
            phoneNum: '',
            taskPhoneNum:'',
        };
    } else {
        console.log('不是在app中')
        return { 
            userId: '1839607344467259393',
            nickName: '光明码圣',
            token: 'a5a7f5fff65545678591187883b63ce6',
            invitationCode: 'MG74626771',
            phoneNum: '',
            taskPhoneNum:'',
            headImage: 'https://thirdwx.qlogo.cn/mmopen/vi_32/QYHKCovO9MCFvlcFb7z4jmibLCHeyFtdIxbgYmcKc3GxMKgmtXRupImojiaMz5HOTxBicia4snYibQL4tyCVxV5ibM0g/132',
        };
        // return { //写死微信号的
        //     userId: '1813385623334772738',
        //     nickName: '暗夜码神',
        //     token: '10c6312a504a42eb9d888740a4d2af06',
        //     invitationCode: 'MX59241419',
        //     phoneNum: '15738533071',
        //     taskPhoneNum:'17065850561',
        // };
    }
}

function finishPage(){
    if (appEvent) {
        appEvent.finishPage()
    } else {
        console.log('不是在app中')
    }
}
/**
 * 打开微信小程序
 */
function openWxMini(originalId,path){
    if (!appEvent) {
        return;
    }
    appEvent.openWxMini(originalId,path);
}
function openDeepLink(url){
    if (!appEvent) {
        return;
    }
    appEvent.openLink(url);
}
function getAppFixedParams(){
    if (!appEvent) {
        return {
            appChannel: '',
            appVersion: 100,
        };
    }
    return JSON.parse(appEvent.getAppFixedParams());
}
function aliAuth(authInfo,callbackName){
    if (!appEvent) {
        return;
    }
    appEvent.authByAli(authInfo,callbackName);
}

function openNewPage(url){
    if (!appEvent) {
        return;
    }
    appEvent.startPage(url);
}
function getLoactionCache(){
    if(!appEvent){
        return {
            latitude: 34.767462,
            longitude:113.732448,
        };
    }
    return JSON.parse(appEvent.getLoactionCache());
}
function getStringCache(key){
    if(!appEvent){
        return null;
    }
    return appEvent.getString(key);
}
function copyText(data){
    if (!appEvent) {
        navigator.clipboard.writeText(data);
        return;
    }
    appEvent.copyToClipboard(data);
}
function setStatusBarColor(color){
    if (!appEvent) {
        return;
    }
    appEvent.setStatusBarColor(color);
}
function upDateTaskPhoneNum(phoneNum){
    if (!appEvent) {
        return;
    }
    appEvent.upDateTaskPhoneNum(phoneNum);
}
function shareWxByUrl(url){
    if (!appEvent) {
        return;
    }
    appEvent.shareUrl("WEIXIN","",url,"熊猫外卖优选","请你5元吃外卖，限时优惠名额");
}
function shareQQByUrl(url){
    if (!appEvent) {
        return;
    }
    appEvent.shareUrl("QQ","",url,"熊猫外卖优选","请你5元吃外卖，限时优惠名额");
}
function sharePyqByUrl(url){
    if (!appEvent) {
        return;
    }
    appEvent.shareUrl("CIRCLE","",url,"熊猫外卖优选","请你5元吃外卖，限时优惠名额");
}
function shareImageByBase64(platform,base64){
    if (!appEvent) {
        return;
    }
    if("WEIXIN" == platform){
        appEvent.shareImageToWxByBase64(base64);
    }else if("CIRCLE" == platform){
        appEvent.shareImageToCircleByBase64(base64,"");
    }

}

function shareWxMini(id,path){
    if (!appEvent) {
        return;
    }
    appEvent.shareWxMini(id,'请你5元吃外卖，限时优惠名额',"",path);
}

function shareWxMiniByBitmap(id,path,base64Img,shareType){
    if (!appEvent) {
        return;
    }
    appEvent.shareWxMiniByBitmap(id,'请你5元吃外卖，限时优惠名额',"",path,base64Img,shareType);
}


function saveImageForBase64(base64){
    if (!appEvent) {
        return;
    }
    appEvent.saveImageForBase64(base64);
}
//订单状态变更通知原生刷新订单
function noticeOrderChange(){
    if (!appEvent) {
        return;
    }
    appEvent.noticeOrderChange();
}

function login(){
    if (!appEvent) {
        return;
    }
    appEvent.login();
}
function openOrderCheck(data){
    if (!appEvent) {
        return;
    }
    appEvent.openOrderCheck(JSON.stringify(data));
}
function showLoading(){
    if (!appEvent) {
        return;
    }
    appEvent.showLoading();
}
function hideLoading(){
    if (!appEvent) {
        return;
    }
    appEvent.hideLoading();
}
function getAppParams(){
    if (!appEvent) {
        return;
    }
    return JSON.parse(appEvent.getAppParams());
}
function wxAuth(funcName){
    if (!appEvent) {
        return;
    }
    appEvent.wxAuth(funcName);
}
function showBindPhoneDialog(funcName){ //102版本新增方法
    if (!appEvent) {
        return false;
    }
    if(this.getAppFixedParams().appVersion < 102){
        return false;
    }
    appEvent.showBindPhoneDialog(funcName);
    return true;
}
function searchPoi(funcName,data){
    if (!appEvent) {
        return false;
    }
    if(this.getAppFixedParams().appVersion < 103){
        return false;
    }
    appEvent.searchPoi(funcName,JSON.stringify(data));
    return true;
}
function selectPoi(data){
    if (!appEvent) {
        return;
    }
    appEvent.selectPoi(JSON.stringify(data));
}
function selectCurrentPoi(){
    if (!appEvent) {
        return;
    }
    appEvent.selectCurrentPoi();
}
function showOpenPoiQrCode(imgUrl){
    if (!appEvent) {
        return;
    }
    appEvent.showOpenPoiQrCode(imgUrl);
}
function setNativeCompress(compress){
    if (!appEvent) {
        return;
    }
    if(this.getAppFixedParams().appVersion < 108){
        return false;
    }
    appEvent.setNativeCompress(compress);
}
function saveImageForUrl(url){
    if (!appEvent) {
        return;
    }
    appEvent.saveImageForUrl(url);
}
function checkApkExist(){
    if (!appEvent) {
        return;
    }
    appEvent.checkApkExist();
}

