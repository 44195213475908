import { get, post, postBody } from "./http";
import { androidFun } from "@/assets/js/androidFun";

export const api = {
  baseUrl: process.env.VUE_APP_BASEURL_DEV,
  params: {
    appChannel: androidFun.getAppFixedParams().appChannel,
    appVersion: androidFun.getAppFixedParams().appVersion,
    userId: androidFun.getUserInfo().userId,
  },
  //检测支付宝绑定状态
  checkAliPayBind: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/pay/getCashBindStatus", param);
  },

  getUserInfo: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/user/getUserInfo", param);
  },
  //绑定支付宝
  bindAliPay: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/pay/bindPay", param);
  },

  //获取支付宝授权信息
  getAliAuthInfo: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/pay/getVerifyIdAndAuthString", param);
  },

  //修改支付宝
  upDateAliPay: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/pay/updatePay", param);
  },

  //支付宝提现
  payCash: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/cash/payCash", param);
  },

  //支付宝提现记录列表
  cashList: function (param = {}) {
    Object.assign(param, this.params);
    return post(
      this.baseUrl +
        `/api/cash/cashList?size=${param.size}&current=${param.current}`,
      param
    );
  },
  //支付宝提现记录详情
  cashDetails: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/cash/cashRecordDetail", param);
  },
  //收支明细
  getAccountDetail: function (param = {}) {
    Object.assign(param, this.params);
    return post(
      this.baseUrl +
        `/api/account/getAccountDetail?size=${param.size}&current=${param.current}`,
      param
    );
  },
  //获取任务详情
  getTaskDetail: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/task/getTaskDetail", param);
  },
  //任务报名
  taskApply: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/task/taskApply", param);
  },
  //任务取消
  taskCancel: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/task/taskCancel", param);
  },
  //修改做任务手机号
  updateTaskPhoneNum: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/user/updateTaskPhoneNum", param);
  },
  //获取订单详情信息
  orderDetail: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/order/orderDetail", param);
  },
  //检测领红包获取的订单信息
  checkRedOrder: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/order/getUserRedEnvelopeOrderInfo", param);
  },
  //任务提交凭据
  taskSubmit: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/task/taskSubmit", param);
  },

  //重新任务提交凭据
  taskReSubmit: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/task/taskReSubmit", param);
  },

  //邀请好友详情页信息
  getUserInviteDetail: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/invite/getUserInviteDetail", param);
  },
  //获取邀请好友记录
  getInviteRecord: function (param = {}) {
    Object.assign(param, this.params);
    return post(
      this.baseUrl +
        `/api/invite/getInviteRecord?size=${param.size}&current=${param.current}`,
      param
    );
  },
  //获取邀请好友收益记录
  getIncomeRecord: function (param = {}) {
    Object.assign(param, this.params);
    return post(
      this.baseUrl +
        `/api/invite/getIncomeRecord?size=${param.size}&current=${param.current}`,
      param
    );
  },

  //获取微信提现绑定信息
  getWxCashBindInfo: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/wx/getWxCashBindInfo", param);
  },
  //修改
  updateWx: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/wx/updateWx", param);
  },
  //微信绑定-APP
  wxBind: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/wx/wxBind", param);
  },
  //微信提现-APP
  wxCash: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/cash/wxCash", param);
  },
  //手动提交订单
  handSubmit: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + "/api/task/handSubmit", param);
  },
  getParamsConfig: function (param = {}) {
    Object.assign(param, this.params);
    return postBody(this.baseUrl + `/sys/list`, param);
  },
  //常用问题列表
  questionCommon: function (param = {}) {
    Object.assign(param, this.params);
    return get(
      this.baseUrl +
        `/api/question/common?size=${param.size}&current=${param.current}`,
      param
    );
  },

  //我的反馈列表
  myQuestionList: function (param = {}) {
    Object.assign(param, this.params);
    return get(
      this.baseUrl +
        `/api/question/myQuestionList?size=${param.size}&current=${param.current}`,
      param
    );
  },
  //提交问题反馈
  submitQuestion: function (param = {}) {
    Object.assign(param, this.params);
    return post(this.baseUrl + `/api/question/submit`, param);
  },
  //查看回复信息
  viewMsg: function (param = {}) {
    Object.assign(param, this.params);
    return get(this.baseUrl + `/api/question/viewMsg`, param);
  },
  //阅读回复信息
  readMsg: function (param = {}) {
    Object.assign(param, this.params);
    return get(this.baseUrl + `/api/question/readMsg`, param);
  },
  //常见问题详情
  getByIdCommon: function (param = {}) {
    Object.assign(param, this.params);
    return get(this.baseUrl + `/api/question/getByIdCommon`, param);
  },
  //返现记录
  getAccountDetailReturn: function (param = {}) {
    Object.assign(param, this.params);
    return post(
      this.baseUrl +
        `/api/account/getAccountDetail?size=${param.size}&current=${param.current}&changeDescType=2&changeType=1`,
      param
    );
  },
};
